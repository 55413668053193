import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Divider, Typography} from "@material-ui/core";
import "./layout.css"
import dayjs from "dayjs";
import { Element } from 'react-scroll';

export default function Footer(props){  

  return (
    <Element name="footer-section">
      <footer>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant="h4">Get in touch</Typography>
              <Typography variant="h2">hi@houstonreid.com</Typography>
            </Grid>
          </Grid>
          <Divider className="divider" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
             <Typography variant="body2">
                &copy; {dayjs().year()} Houston Reid. All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </footer> 
    </Element>             
  );
};

Footer.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      contactEmail: PropTypes.string,
      contactPhone: PropTypes.string,
      facebookLink: PropTypes.string,
      twitterLink: PropTypes.string,
      instagramLink: PropTypes.string,
    })
  ),
};
