import React from "react";
import { List, ListItem, IconButton, Grid, Drawer, ListItemText } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { scroller } from 'react-scroll';
import logo from '../img/logo.svg'
//import logo_white from '../img/logo_white.svg'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
  width: 350,
    backgroundColor: '#016260',
    height: '100%'
  },
  fullList: {
    width: 'auto',
  },
});

export default function FixedNavBar() {

  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // Scrolling to relevant sections
  const onClickScrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      // style={{backgroundColor:"#016260", height:"100%"}}
    >
      <List className="mobile-nav-container">
          <ListItem button key={'Latest Work'} onClick={() => onClickScrollToSection("mywork-section")}>
            <ListItemText primary={'Latest Work'} />
          </ListItem>
          <ListItem button key={'About Me'} onClick={() => onClickScrollToSection("aboutme-section")}>
            <ListItemText primary={'About Me'} />
          </ListItem>
          {/* <ListItem button key={'My Services'} onClick={() => onClickScrollToSection("myservices-section")}>
            <ListItemText primary={'My Services'} />
          </ListItem> */}
          <ListItem button key={'Get In Touch'} onClick={() => onClickScrollToSection("footer-section")}>
            <ListItemText primary={'Get In Touch'} />
          </ListItem>
      </List>
    </div>
  );

  return (
    <nav className="fixed-navbar">
      <Grid container>
        <Grid item xs={8} sm={8} md={8} className="logo">
            <img src={logo} alt="Houston Reid: Full Stack Developer Logo" placeholder="blurred"/>
        </Grid>

          <Grid item xs={4} className="mobileNavIconWrapper">
            <IconButton aria-label="" onClick={toggleDrawer('right', true)}>
              <MenuIcon fontSize="large" />
            </IconButton> 
            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
              {list('right')}
            </Drawer>
          </Grid>
      </Grid>
    </nav>
    );
  };